<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-assignor-locations' }">{{
            $t("MENU.ITEM.ASL.ASSIGNORS_LOCATIONS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton v-on:onDelete="onDelete()"></DeleteButton>
          <SaveButtonDropdown
            class="mr-2"
            :isLoading="isLoadingLocation"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
          <button class="btn btn-info mr-2" @click="openModal">
            {{ $t("MENU.ITEM.SRV.SERVICES") }}
          </button>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <b-row>
            <b-col cols="12" sm="4" md="4">
              <b-label class="pl-1 pt-2">{{
                $t("MENU.ITEM.ASL.LOCATION_NAME")
              }}</b-label>

              <FormInput
                :id="locationName"
                :class="'mt-3'"
                :type="'text'"
                :name="'locationName'"
                :required="'true'"
                :feedback_id="'locationName-feedback'"
                :i18n="{}"
                :validations="'validations: {required: true, minLength: 2}'"
                :validateState="validateState('locationName')"
                v-model="form.locationName"
              ></FormInput>
            </b-col>

            <b-col cols="12" sm="4" md="4">
              <b-label class="ml-0 pl-0">
                {{ $t("MENU.ITEM.ASL.ASSIGNOR") }}
                <FormSelect
                  id="item-item-category-input"
                  type="select"
                  :options="companies"
                  v-model="form.locateableId"
                  class="col-sm-12"
                ></FormSelect>
              </b-label>
            </b-col>

            <b-col cols="12" sm="4" md="4">
              <b-label class="pl-0">
                {{ $t("MENU.ITEM.ASL.CODE") }}
              </b-label>
              <FormInput
                :class="'mt-3'"
                type="number"
                max="99999"
                name="code"
                required
                feedback_id="code-feedback"
                :i18n="{}"
                :validations="{}"
                v-model="form.code"
              ></FormInput>
            </b-col>
          </b-row>
          <b-label class="ml-0 pl-0">
            {{ $t("FORM_LABELS.HAS_LOCATION") }}
            <FormSwitch
              :id="has_location"
              type="checkbox"
              :name="has_location"
              v-model="form.has_location"
              @input="switched($event)"
            ></FormSwitch>
          </b-label>

          <gmap-map
            ref="mapRef"
            v-if="!firstLoader && form.has_location"
            :center="map.center"
            :zoom="map.zoom"
            style="width: 100%; height: 500px"
            @dragend="updateMapOptions"
          >
            <gmap-marker
              v-for="(usedMarker, index) in usedMarkers"
              v-bind:key="`marker-${index}`"
              :position="usedMarker"
              :clickable="true"
              :draggable="false"
              icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
            ></gmap-marker>
            <gmap-marker
              :position="marker.position"
              :clickable="true"
              :draggable="true"
              @dragend="updateCoordinates"
              @click="map.center = marker.position"
            ></gmap-marker>
            <gmap-polygon
              v-if="paths.length > 0"
              :paths="paths"
              :editable="true"
              :options="{ strokeWeight: 0.2 }"
              @paths_changed="updateEdited($event)"
              @rightclick="handleClickForDelete"
              ref="polygon"
            >
            </gmap-polygon>
            <gmap-polygon
              v-if="usedPaths.length > 0"
              :paths="usedPaths"
              :editable="false"
              :options="{ fillColor: 'red', strokeWeight: 0.2 }"
              ref="usedPolygon"
            >
            </gmap-polygon>
          </gmap-map>
          <b-form-row v-if="!firstLoader && form.has_location">
            <b-col>
              <b-form-group
                v-bind:id="'location-map-options'"
                v-bind:label="$t('FORM_LABELS.SEARCH_PLACE')"
                v-bind:label-for="'map-search-location'"
              >
                <div id="map-search-location" class="input-group">
                  <gmap-autocomplete
                    class="form-control"
                    @place_changed="setPlace"
                  >
                  </gmap-autocomplete>
                  <div class="input-group-append">
                    <span
                      class="btn btn-outline-primary"
                      @click="changeMarkerBySearch"
                      ><i class="flaticon-search"></i>
                      {{ $t("LABELS.SEARCH") }}</span
                    >

                    <span
                      class="ml-5 btn btn-outline-warning"
                      @click="resetPath()"
                      ><i class="flaticon-map-location"></i>
                      {{ $t("LABELS.ADD_POLYGON") }}</span
                    >
                    <span
                      class="ml-5 btn btn-outline-warning"
                      @click="removePath()"
                      ><i class="flaticon2-map"></i>
                      {{ $t("LABELS.RESET_POLYGON") }}</span
                    >
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton v-on:onDelete="onDelete()"></DeleteButton>
          <SaveButtonDropdown
            class="mr-2"
            :defaultAction="'continue'"
            :isLoading="isLoadingLocation"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
          <button class="btn btn-info mr-2" @click="openModal">
            {{ $t("MENU.ITEM.SRV.SERVICES") }}
          </button>
        </div>
      </template>
    </KTCard>
    <ServicesModal
      ref="ServicesModal"
      :location-id="$route.params.id"
    ></ServicesModal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import {
  FETCH_ASSIGNOR_LOCATION,
  UPDATE_ASSIGNOR_LOCATION,
  DELETE_ASSIGNOR_LOCATION,
} from "@/modules/assignorslocation/store/location.module";

import ServicesModal from "@/modules/bill-of-lading/pages/ServicesModal.vue";
import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import locationMixin from "./mixins/location.mixin";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import { gmapMixin } from "@/core/mixins/gmapMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";

export default {
  name: "LocationEdit",
  mixins: [validationMixin, formBuilderMixin, gmapMixin, locationMixin],
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    FormSwitch,
    FormSelect,
    FormInput,
    vSelect,
    ServicesModal,
  },
  data() {
    return {
      location: {},
      observer: null, //Infinite scroll v-select
      params: {
        page: 1,
        itemsPerPage: 50,
        query: "",
      },
      old: {
        code: null,
      },
      form: {
        locationName: "",
        locateableId: null,
        has_location: true,
        lat: 42.695537,
        lng: 23.253907,
        code: null,
      },
      firstLoader: true,
      paths: [],
      usedPaths: [],
      usedMarkers: [],
      mvcPaths: null,
      errorMessage: null,
      polygonGeojson: "",
      companies: [],
      assignors: [],
      subcontractors: [],
      map_lat: 42.695537,
      map_lon: 23.253907,
      has_location: true,
      locationName: "",
    };
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ASL.ASSIGNORS_LOCATIONS"),
        route: { name: "list-assignor-locations" },
      },
      { title: this.$i18n.t("MENU.ITEM.EDIT") },
    ]);

    vm.getLocation();
  },
  watch: {
    polygonPaths: _.throttle(function(paths) {
      if (paths) {
        this.paths = paths;
        this.polygonGeojson = JSON.stringify(
          this.paths.map(({ lat, lng }) => [lat, lng]),
          null,
          2
        );
      }
    }, 1000),
  },
  computed: {
    ...mapGetters(["isLoadingLocation"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    polygonPaths: function() {
      if (!this.mvcPaths) return null;

      let paths = [];
      for (let i = 0; i < this.mvcPaths.getLength(); i++) {
        let path = [];
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j++) {
          let point = this.mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths = path;
      }
      var bounds = this.$refs.mapRef.$mapObject.getCenter();
      this.form.lat = bounds.lat();
      this.form.lng = bounds.lng();

      return paths;
    },
    schemaJson() {
      return {
        fields: [],
      };
    },
    marker() {
      return {
        position: {
          lat: parseFloat(this.form.lat),
          lng: parseFloat(this.form.lng),
        },
      };
    },
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const locationNameValidations = {
      required: true,
      minLength: 2,
    };

    tmpValidationObject.form["locationName"] = vm.setItemValidations(
      locationNameValidations
    );
    return tmpValidationObject;
  },
  methods: {
    switched(e) {
      this.form.has_location = e;
    },
    get_assignors() {
      this.$store.dispatch(FETCH_ASSIGNORS).then((data) => {
        let items = data.data.items;

        let comp = [];
        if (items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            comp.push({ id: items[i].id, name: items[i].companyName });
          }
        }
        this.companies = comp;
      });
    },
    getLocation() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_ASSIGNOR_LOCATION, id)
        .then((data) => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.location = data.data;
            vm.form.locationName = data.data.locationName;
            vm.old.code = data.data.code;
            vm.form.code = data.data.code;

            vm.get_assignors();
            vm.form.has_location = true;

            vm.form.locateableId = data.data.companyId;
            let gpsArea = data.data.gpsArea;

            if (gpsArea != "") {
              let pa = gpsArea;
              //console.log(pa)
              let arr = [];
              for (let i = 0; i < pa.length; i++) {
                arr.push({ lng: pa[i][0], lat: pa[i][1] });
              }
              vm.paths = arr;
            }
            let lat =
              data.data.lat != "" ? parseFloat(data.data.lat) : 42.695537;
            let lon =
              data.data.lon != "" ? parseFloat(data.data.lon) : 23.253907;

            this.map_lat = lat;
            this.map_lon = lon;
            this.form.lat = lat;
            this.form.lng = lon;
            vm.map.center = {
              lat: lat,
              lng: lon,
            };
            this.$refs.mapRef.$mapPromise.then(
              (refMap) => (this.coreMap = refMap)
            );

            setTimeout(() => {
              vm.form.has_location = data.data.gpsArea == "" ? false : true;
            }, 1000);
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found",
            });
            this.$router.push({ name: "list-assignor-locations" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    async onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }

      if (this.old.code !== this.form.code) {
        await this.checkDuplicateCodes(this.form.code);
      }

      if (this.form.has_location) {
        var bounds = this.$refs.mapRef.$mapObject.getCenter();
        this.form.lat = bounds.lat();
        this.form.lng = bounds.lng();
        vm.$set(vm.form, "gpsArea", vm.paths);
      } else {
        this.form.lat = null;
        this.form.lng = null;
        vm.$set(vm.form, "gpsArea", false);
      }

      this.$store
        .dispatch(UPDATE_ASSIGNOR_LOCATION, {
          id: vm.location.id,
          payload: this.form,
        })
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            // // this.$router.push({ name: "edit-location", params: { id: itemEdit.id } });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-assignor-location" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-assignor-locations" });
          }
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_ASSIGNOR_LOCATION, vm.location.id)
            .then((data) => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message,
              });
              vm.$router.push({ name: "list-assignor-locations" });
            })
            .catch((response) => {
              vm.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response.message,
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    },
    onClose() {
      this.observer.disconnect();
    },
    //Google Maps
    initCenterMap: function() {
      let vm = this;
      vm.map.center = {
        lat: this.map_lat,
        lng: this.map_lon,
      };
      this.$refs.mapRef.$mapPromise.then((refMap) => (this.coreMap = refMap));
    },
    updateCoordinates(location) {
      this.form.lat = location.latLng.lat();
      this.form.lng = location.latLng.lng();
    },
    resetMarker() {
      this.map.center = {
        lat: this.map_lat,
        lng: this.map_lon,
      };
      this.$refs.mapRef.$mapPromise.then((refMap) => (this.coreMap = refMap));
    },
    changeMarkerBySearch() {
      let vm = this;
      if (this.map.currentPlace) {
        vm.form.lat = vm.map.currentPlace.geometry.location.lat();
        vm.form.lng = vm.map.currentPlace.geometry.location.lng();
        vm.map.center = {
          lat: vm.map.currentPlace.geometry.location.lat(),
          lng: vm.map.currentPlace.geometry.location.lng(),
        };
        vm.currentPlace = null;
      }
    },
    //GEOJSON POLYGON
    updateEdited: function(mvcPaths) {
      this.mvcPaths = mvcPaths;
    },
    resetPath: function() {
      let vm = this;
      // obtain the bounds, so we can guess how big the polygon should be
      var bounds = this.$refs.mapRef.$mapObject.getBounds();
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      var center = bounds.getCenter();
      var degree = this.paths.length + 1;
      var f = Math.pow(0.66, degree);

      // Draw a triangle. Use f to control the size of the triangle.
      // i.e., every time we add a path, we reduce the size of the triangle
      var path = [
        {
          lng: center.lng(),
          lat: (1 - f) * center.lat() + f * northEast.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * northEast.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
      ];
      vm.paths = path;
      this.map.center = {
        lat: center.lat(),
        lng: center.lng(),
      };
      this.$refs.mapRef.$mapPromise.then((refMap) => (this.coreMap = refMap));
    },
    removePath: function() {
      let vm = this;
      vm.paths.splice(this.paths.length - 1, 1);
      vm.polygonGeojson = JSON.stringify(
        this.paths.map(({ lat, lng }) => [lat, lng]),
        null,
        2
      );
    },
    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },
    openModal() {
      this.$refs.ServicesModal.show();
    },
  },
};
</script>
